export const serviceVersion = '2023-05-24';

export type ServiceParams = {
	getUser: {
		userId: string;
	};
};

export type ServiceResponses = {
	getUser: User;
};

type User = {
	username: string;
	avatarUrl: string | null;
};
