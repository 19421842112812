import { ISO8601DateTime } from '../types';

export const serviceVersion = '2024-01-31';

export type ServiceParams = {
	getUser: {
		userId: string;
	};
	registerUserUnverified: {
		sessionId: string;
		email: string;
		emailMarketingConsent: ConsentDecision;
	};
	registerUserWithCredToken: {
		sessionId: string;
		credToken: string;
		emailMarketingConsent: ConsentDecision | null;
	};
	loginUser: {
		sessionId: string;
		credToken: string;
	};
};

export type ServiceResponses = {
	getUser: User;
	registerUserUnverified: AuthzToken;
	registerUserWithCredToken: AuthzToken;
	loginUser: AuthzToken;
};

type User = {
	userId: string;
	createdAt: ISO8601DateTime;
	emailMarketingConsent: ConsentDecision | null;
};

export type ConsentDecision = {
	type: 'soft_opt_in';
	consented: boolean;
};

type AuthzToken = {
	authzToken: string;
};
