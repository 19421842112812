enum Service {
	analyticsevent = 'analyticsevent',
	attribution = 'attribution',
	authcredapple = 'authcredapple',
	authcredemail = 'authcredemail',
	authcredgoogle = 'authcredgoogle',
	authsession = 'authsession',
	authuser = 'authuser',
	authuserdeletion = 'authuserdeletion',
	authuseridentifier = 'authuseridentifier',
	authuserimpersonation = 'authuserimpersonation',
	clientsdkconfig = 'clientsdkconfig',
	codeverifyemail = 'codeverifyemail',
	communitycontent = 'communitycontent',
	communityprofile = 'communityprofile',
	communityreaction = 'communityreaction',
	communitysso = 'communitysso',
	contentvalidation = 'contentvalidation',
	customeriosync = 'customeriosync',
	dailychallenge = 'dailychallenge',
	dailytask = 'dailytask',
	efficacymeasurement = 'efficacymeasurement',
	experimentuserdata = 'experimentuserdata',
	featureconfig = 'featureconfig',
	inapppurchase = 'inapppurchase',
	intercomconfig = 'intercomconfig',
	itoinnercritic = 'itoinnercritic',
	learntracking = 'learntracking',
	ping = 'ping',
	poll = 'poll',
	pseudonym = 'pseudonym',
	pushnotification = 'pushnotification',
	soundtracking = 'soundtracking',
	staff = 'staff',
	subscriptioncancelation = 'subscriptioncancelation',
	subscriptioncheckout = 'subscriptioncheckout',
	subscriptionstatus = 'subscriptionstatus',
	testauthcred = 'testauthcred',
	testauthuser = 'testauthuser',
	testautomation = 'testautomation',
	testsubscription = 'testsubscription',
	trackingdate = 'trackingdate',
	userpreference = 'userpreference',
}

export default Service;
