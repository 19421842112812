import { BlockContentType } from '@wearemojo/sanity-schema';

import { ISO8601DateTime } from '../types';

export const serviceVersion = '2024-03-04';

export type ServiceParams = {
	listCategories: void;
	listTopics: {
		categoryIds: string[] | null;
		order: SortByOrder;
	};
	getTopic: {
		topicId: string;
	};
	listReplies: {
		topicId: string;
	};
	createTopic: {
		userId: string;
		categoryId: string;
		title: string;
		contentRaw: string;
	};
	createReply: {
		userId: string;
		topicId: string;
		parentReplyId: string | null;
		contentRaw: string;
	};
};

export type SortByOrder =
	| 'latest_updated_first'
	| 'hot'
	| 'most_liked'
	| 'most_replies'
	| 'newest_first'
	| 'oldest_first';

export type ServiceResponses = {
	listCategories: Category[];
	listTopics: TopicListing[];
	getTopic: TopicFull;
	listReplies: Reply[];
	createTopic: TopicID;
	createReply: ReplyID;
};

type TopicID = {
	topicId: string;
};

type ReplyID = {
	replyId: string;
};

type Category = {
	id: string;
	name: string;
};

type Poster = {
	displayName: string;
	isStaff: boolean;
	avatarUrl: string;
};

type ContentBase = {
	id: string;
	createdAt: ISO8601DateTime;
	poster: Poster; // TODO: shift to communityprofile
};

type TopicBase = ContentBase & {
	categoryId: string | null;
	replyCount: number;
	title: string;
};

type TopicListing = TopicBase & {
	excerpt: string;
};

type TopicFull = TopicBase & {
	content: BlockContentType;
};

type Reply = ContentBase & {
	parentReplyId: string | null;
	content: BlockContentType;
};

export type CreateTopic = ServiceParams['createTopic'];
