import { ISO8601DateTime } from '../types';

export const serviceVersion = '2022-11-24';

export type ServiceParams = {
	listImpersonations: {
		includeExpired: boolean;
		includeImplicit: boolean;
	};
};

export type ServiceResponses = {
	listImpersonations: Impersonation;
};

type Impersonation = {
	id: string;
	targetUserId: string;
	expiresAt: ISO8601DateTime;
	type: 'explicit' | 'implicit';
	audit: {};
};
