import {
	ApprovalCode,
	CredTokenResponse,
	ISO8601DateTime,
	MagicLinkParams,
} from '../types';

export const serviceVersion = '2022-11-24';

export type ServiceParams = {
	sendMagicLink: {
		sessionId: string;
		email: string;
		state: string;
		redirectUri: string;
		purpose: 'authentication';
	};
	exchangeToken: MagicLinkParams;
	approverGetStatus: MagicLinkParams;
	approverApprove: MagicLinkParams;
	approverReject: MagicLinkParams;
	requesterGetStatus: {
		sessionId: string;
		magicLinkId: string;
	};
	requesterExchangeCode: {
		sessionId: string;
		magicLinkId: string;
		approvalCode: string;
	};
};

export type ServiceResponses = {
	sendMagicLink: SendMagicLinkResponse;
	exchangeToken: CredTokenResponse;
	approverGetStatus: void;
	approverApprove: ApprovalCode;
	approverReject: void;
	requesterGetStatus: ApprovalCodeLength;
	requesterExchangeCode: CredTokenResponse;
};

type SendMagicLinkResponse = {
	expiresAt: ISO8601DateTime;
	magicLinkId: string;
};

type ApprovalCodeLength = {
	approval: {
		codeLength: number;
	} | null;
};
