export const serviceVersion = '2023-11-09';

export type ServiceParams = {
	getRevenuecatConfig: {
		userId: string;
		platform: 'ios' | 'android';
		applicationId: string;
	};
	getRevenuecatUserEntitlements: {
		userId: string;
	};
};

export type ServiceResponses = {
	getRevenuecatConfig: {
		apiKey: string;
		appUserId: string;
	};
	getRevenuecatUserEntitlements: {
		activeEntitlements: {
			name: string;
			expiresAt: string | null;
			periodType: 'normal' | 'trial' | 'intro';
			store: SubscriptionStores;
			cancelAtPeriodEnd: boolean;
		}[];
	};
};

export type SubscriptionStores =
	| 'app_store'
	| 'play_store'
	| 'stripe'
	| 'promotional';
