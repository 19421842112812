export const serviceVersion = '2022-11-24';

export type ServiceParams = {
	sendVerificationEmail: {
		sessionId: string;
		purpose: 'registration' | 'validate_email' | 'change_email';
		email: string;
	};
	confirmVerificationEmail: {
		sessionId: string;
		email: string;
		code: string;
	};
};

export type ServiceResponses = {
	sendVerificationEmail: void;
	confirmVerificationEmail: VerificationToken;
};

type VerificationToken = {
	verificationToken: string;
};
