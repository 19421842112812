export const serviceVersion = '2024-03-04';

export type ServiceParams = {
	listReactionStats: void;
	listUserReactions: {
		userId: string;
	};
	toggleUserReaction: ContentReactionKey & {
		userId: string;
	};
};

export type ServiceResponses = {
	listReactionStats: ContentReactionCount[];
	listUserReactions: ContentReactionKey[];
	toggleUserReaction: void;
};

export type ContentType = 'topic' | 'reply';

type ContentReactionKey = {
	contentType: ContentType;
	contentIdentifier: string;
	reactionIdentifier: string;
};

type ContentReactionCount = ContentReactionKey & {
	count: number;
};
