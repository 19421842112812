import { ISO8601DateTime, Price } from '../types';

export const serviceVersion = '2024-04-23';

export type ServiceParams = {
	getSubscriptionStatus: {
		userId: string;
		forceRefresh: boolean;
	};
	getBillingPortalUrl: {
		userId: string;
		returnUrl: string;
	};
	getLatestInvoiceUrl: {
		userId: string;
	};
	getCurrentSubscription: {
		userId: string;
	};
};

export type ServiceResponses = {
	getSubscriptionStatus: SubscriptionStatus;
	getBillingPortalUrl: URL;
	getLatestInvoiceUrl: URL;
	getCurrentSubscription: Subscription;
};

type URL = {
	url: string;
};

export enum Status {
	Trialing = 'trialing',
	Active = 'active',
	PastDue = 'past_due',
}

export type SubscriptionStore =
	| 'app_store'
	| 'play_store'
	| 'stripe'
	| 'promotional';

type SubscriptionStatus = {
	status: Status;
	store: SubscriptionStore | null;
	provider: ProviderType<SubscriptionStatus['store']>;
	cancelAtPeriodEnd: boolean;
	isActiveMember: boolean;
};

type Subscription = {
	status: Status;
	isActiveMember: boolean;
	store: SubscriptionStore | null;
	provider: ProviderType<Subscription['store']>;
	currency: string;
	interval: string;
	intervalCount: number;
	cancelAtPeriodEnd: CancelAtPeriodEnd | null;
	currentExpectedEvent: Event | null;
};

type ProviderType<Store> = Store extends 'stripe'
	? 'stripe'
	: Store extends 'app_store' | 'play_store' | 'promotional'
		? 'revenue_cat'
		: null;

type CancelAtPeriodEnd = {
	requestedAt: ISO8601DateTime;
	expectedEnd: ISO8601DateTime;
};

type EventBase = {
	code: string;
	timestamp: ISO8601DateTime;
	params: {};
};

type Event = EventSubscriptionInvoicePaid | EventSubscriptionEnded;

type EventSubscriptionInvoicePaid = EventBase & {
	code: 'subscription_invoice_paid';
	params: {
		invoiceUrl: string | null;
		price: Price;
	};
};

type EventSubscriptionEnded = EventBase & {
	code: 'subscription_ended';
	params: {};
};
