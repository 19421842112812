export const serviceVersion = '2022-04-05';

export type ServiceParams = {
	getConfig: void;
};

export type ServiceResponses = {
	getConfig: Config;
};

type Config = {
	sanity: {
		projectId: string;
		dataset: string;
		useCdn: boolean;
	};
	mixpanel: {
		enabled: boolean;
		serverUrl: string;
		projectToken: string;
	};
	langgraph: null | {
		apiUrl: string;
		apiKey: string;
	};
};
