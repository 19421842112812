export const serviceVersion = '2024-08-08';

export type ServiceParams = {
	getConfig: void;
	getUserConfig: {
		userId: string;
	};
};

export type ServiceResponses = {
	getConfig: Config;
	getUserConfig: UserConfig;
};

type Config = {
	siteId: string;
	apiKey: string;
	region: 'US' | 'EU';
};

type UserConfig = {
	identifier: string;
};
