export const serviceVersion = '2022-08-08';

export type ServiceParams = {
	getKeys: {
		userId: string;
	};
	setItem: {
		userId: string;
		key: string;
		value: Record<string, unknown> | null;
	};
	getItem: {
		userId: string;
		key: string;
	};
};

export type ServiceResponses = {
	getKeys: string[];
	setItem: void;
	getItem: {
		userId: string;
		key: string;
		value: Record<string, unknown> | null;
	};
};
