export const serviceVersion = '2023-06-07';

export type ServiceParams = {
	cancelSubscription: {
		userId: string;
		reasonKey: string;
		params: {
			subReasonKey: string | null;
			prompt: string | null;
		};
	};
	checkCancelationDiscount: {
		userId: string;
	};
	applyCancelationDiscount: {
		userId: string;
	};
};

export type ServiceResponses = {
	cancelSubscription: void;
	checkCancelationDiscount: void;
	applyCancelationDiscount: void;
};
