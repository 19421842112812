import { ISO8601Date } from '../types';

export const serviceVersion = '2024-06-04';

export type ServiceParams = {
	getTasks: {
		userId: string;
		timezone: string;
	};
	listDateStates: {
		userId: string;
		timezone: string;
	};
	getCurrentCoinBalance: {
		userId: string;
		timezone: string;
	};
	arrangeStreakRestDays: {
		userId: string;
		timezone: string;
		restDays: {
			trackingDate: string;
			netCoins: number;
		}[];
	};
	invalidateTasks: {
		userId: string;
		timezone: string;
		trackingDate: ISO8601Date;
	};
};

export type ServiceResponses = {
	getTasks: Tasks;
	listDateStates: DateState[];
	getCurrentCoinBalance: CoinBalance;
	arrangeStreakRestDays: void;
	invalidateTasks: void;
};

type Tasks = {
	// hidden because it should be retrieved from the `trackingdate` service
	// currentTrackingDate: ISO8601Date;

	items: Task[];
};

type Task = ActivityTask;

type BaseTask = {
	type: string;
	completed: boolean;
	params: {};
};

type ActivityTask = BaseTask & {
	type: 'activity';
	params: {
		activityId: string;
	};
};

type DateState = {
	date: ISO8601Date;
	status: 'complete' | 'partially_complete';
};

type CoinBalance = {
	coinBalance: number;
};
