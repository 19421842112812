import { ISO8601Date } from '../types';

export const serviceVersion = '2023-09-18';

export type ServiceParams = {
	getCurrentTrackingDate: {
		userId: string;
		timezone: string;
	};
	testIncrementTrackingDate: {
		userId: string;
		timezone: string;
	};
};

export type ServiceResponses = {
	getCurrentTrackingDate: {
		trackingDate: ISO8601Date;
	};
	testIncrementTrackingDate: void;
};
