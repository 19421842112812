const camelCaseRegex = /^[a-z][A-Za-z0-9]*$/;
const snakeCaseRegex = /^[a-z]+(?:_[a-z0-9]+)*$/;
const snakeCaptureRegex = /_(.)/g;

let compiledCamelSplitRegex: RegExp | null = null;

// in the grass
export function snek<T>(object: unknown): T {
	if (typeof window === 'undefined') {
		return snekNode(object) as T;
	}

	return snekBrowser(object) as T;
}

function snekBrowser(object: unknown) {
	const camelSplitFirstPassRegex = /([A-Z])/g;
	const camelSplitSecondPassRegex = /([a-zA-Z]{1})([0-9]{1})/g;

	return processKeys((s) => {
		if (!camelCaseRegex.test(s)) {
			return s;
		}

		return s
			.replace(camelSplitFirstPassRegex, ' $1')
			.split(' ')
			.flatMap((_s) =>
				_s.replace(camelSplitSecondPassRegex, '$1 $2').split(' '),
			)
			.join('_')
			.toLowerCase();
	}, object);
}

function snekNode(object: unknown) {
	if (!compiledCamelSplitRegex) {
		compiledCamelSplitRegex = new RegExp('(?=[A-Z])|(?<=[a-z])(?=[0-9])', 'g');
	}

	return processKeys((s) => {
		if (!camelCaseRegex.test(s)) {
			return s;
		}

		return s.split(compiledCamelSplitRegex!).join('_').toLowerCase();
	}, object);
}

export function desnek<T>(object: unknown): T {
	return processKeys((s) => {
		if (!snakeCaseRegex.test(s)) {
			return s;
		}

		return s.replace(snakeCaptureRegex, (match, chr) => {
			return chr ? chr.toUpperCase() : '';
		});
	}, object) as T;
}

function processKeys(convert: (s: string) => string, obj: unknown): unknown {
	if (obj !== Object(obj) || typeof obj === 'function') {
		return obj;
	}

	if (
		['[object Date]', '[object RegExp]', '[object Boolean]'].includes(
			Object.prototype.toString.call(obj),
		)
	) {
		return obj;
	}

	if (Array.isArray(obj)) {
		const output = [];

		for (const item of obj) {
			output.push(processKeys(convert, item));
		}

		return output;
	}

	const output: Record<string, unknown> = {};
	const objTyped = obj as Record<string, unknown>;

	for (const key of Object.keys(objTyped)) {
		output[convert(key)] = processKeys(convert, objTyped[key]);
	}

	return output;
}
