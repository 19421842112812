export const serviceVersion = '2024-10-17';

export type ServiceParams = {
	getChallenges: {
		userId: string;
		timezone: string;
	};
	acceptChallenge: {
		userId: string;
		challengeId: string;
		timezone: string;
	};
	declineChallenge: {
		userId: string;
		challengeId: string;
		timezone: string;
	};
};

export type ServiceResponses = {
	getChallenges: Challenges;
	acceptChallenge: void;
	declineChallenge: void;
};

type Challenges = {
	declinedChallengeIds: string[];
	acceptedChallenges: Challenge[];
};

type Challenge = {
	id: string;
	completionsRequired: number;
	completionsDone: number;
	awardCoins: number;
	contributingActivityIds: string[];
};
