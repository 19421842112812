import { CredTokenResponse, Identifier } from '../types';

export const serviceVersion = '2022-11-24';

export type ServiceParams = {
	createToken: {
		sessionId: string;
		purpose: 'authentication' | 'verify_identifier' | 'attach_identifier';
		identifiers: Identifier[];
	};
};

export type ServiceResponses = {
	createToken: CredTokenResponse;
};
