import Cher from '@wearemojo/cher';

export const serviceVersion = '2024-06-11';

export type ServiceParams = {
	validateProgram: void;
};

export type ServiceResponses = {
	validateProgram: ValidationResponse;
};

type ValidationResponse = {
	errors: Cher[];
	warnings: Cher[];
	info: Cher[];
};
