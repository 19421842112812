export default class Cher<
	TCode extends string = string,
	TMeta extends Record<string, unknown> = Record<string, unknown>,
> extends Error {
	code: TCode;
	meta?: TMeta;
	reasons?: Cher[];

	constructor(code: TCode, meta?: TMeta, reasons?: Cher[]) {
		super(code);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, this.constructor);
		}

		// make nested errors enumerable
		recursivelyMakeErrorsEnumerable(meta);

		this.code = code;
		this.meta = meta;
		this.reasons = reasons;
	}

	static isCode<TCode extends string>(
		obj: unknown,
		...codes: TCode[]
	): obj is Cher<TCode> {
		return obj instanceof Cher && codes.includes(obj.code);
	}

	static parseOrWrap(obj: unknown, code: string): Cher {
		try {
			return Cher.parse(obj as Cher | Record<string, unknown>);
		} catch {
			return new Cher(code, { error: obj });
		}
	}

	static parse(obj: Cher | Record<string, unknown>): Cher {
		if (obj instanceof Cher) {
			return obj;
		}

		if (typeof obj.code !== 'string') {
			throw new TypeError('Cher.parse: code must be a string');
		}

		const cher = new Cher(obj.code);

		if (obj.reasons != null) {
			if (!Array.isArray(obj.reasons)) {
				throw new TypeError('Cher.parse: reasons must be an array');
			}

			cher.reasons = obj.reasons.map((r) => {
				if (!r || typeof r !== 'object' || Array.isArray(r)) {
					throw new TypeError(
						'Cher.parse: reasons must be an array of objects',
					);
				}

				return Cher.parse(r as Record<string, unknown>);
			});
		}

		if (obj.meta != null) {
			if (typeof obj.meta !== 'object' || Array.isArray(obj.meta)) {
				throw new TypeError('Cher.parse: meta must be an object');
			}

			cher.meta = obj.meta as Record<string, unknown>;
		}

		if (obj instanceof Error) {
			cher.stack = obj.stack;
		}

		return cher;
	}
}

const makeErrorEnumerable = (error: Error, includeStack: boolean = false) => {
	Object.defineProperty(error, 'name', { enumerable: true, value: error.name });
	Object.defineProperty(error, 'message', { enumerable: true });

	if (includeStack) {
		Object.defineProperty(error, 'stack', { enumerable: true });
	}
};

const recursivelyMakeErrorsEnumerable = (
	value: unknown,
	includeStack: boolean = false,
) => {
	if (Array.isArray(value)) {
		for (const item of value) {
			recursivelyMakeErrorsEnumerable(item, includeStack);
		}

		return;
	}

	if (typeof value === 'object' && value != null) {
		if (value instanceof Error) {
			makeErrorEnumerable(value, includeStack);
		}

		for (const item of Object.values(value)) {
			recursivelyMakeErrorsEnumerable(item, includeStack);
		}
	}
};
