export const serviceVersion = '2023-02-07';

export type ServiceParams = {
	isStaffMember: {
		userId: string;
	};
};

export type ServiceResponses = {
	isStaffMember: Staff;
};

type Staff = {
	isStaffMember: boolean;
};
