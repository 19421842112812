export const serviceVersion = '2024-06-25';

export type ServiceParams = {
	getFeaturesRaw: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		mustUseSession: boolean;
	};
};

export type ServiceResponses = {
	// `disableResponseDesnek` should be enabled for this response
	getFeaturesRaw: RawFeatures;
};

type RawFeatures = {
	values: Record<string, unknown>;
	experiments: Record<string, ExperimentResult>;
};

type ExperimentResult = {
	experiment_key: string;
	result_key: string;
};
