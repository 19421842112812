import { CallOptions, ClientContext, ISO8601DateTime } from '../types';

export const serviceVersion = '2023-08-10';

export type ServiceParams = {
	attachUserWithAuthzToken: {
		sessionId: string;
		authzToken: string;
		context: ClientContext;
	};
	createSession: {
		clientId: string;
		context: ClientContext;
		reason: {
			code: string;
			meta?: Record<string, unknown>;
		};
	};
	refreshSession: {
		clientId: string;
		context: ClientContext;
		grantType: string;
		refreshToken: string;
	};
	endSession: {
		sessionId: string;
		includeChildren: boolean;
		reason: {
			code: string;
			meta?: Record<string, unknown>;
		};
	};
	getFirstSession: {
		userId: string;
	};
};

export type ServiceResponses = {
	attachUserWithAuthzToken: UserID;
	createSession: TokenResponse;
	refreshSession: TokenResponse;
	endSession: void;
	getFirstSession: SessionInfo;
};

type TokenResponse = {
	accessToken: string;
	refreshToken: string;
	expiresIn: number;
	expiresAt: ISO8601DateTime;
	tokenType: string;
	sessionId: string;
};

type UserID = {
	userId: string;
};

type SessionInfo = {
	sessionId: string;
	clientId: string;
	userId: string | null;
	context: ClientContext;
};

// old style client below

type Doer = {
	do<TReq, TRes>(
		name: `s-${string}`,
		version: string,
		method: string,
		params: TReq,
		opts?: CallOptions,
	): Promise<TRes>;
};

export { AuthSessionClient as Client };
class AuthSessionClient {
	private doer: Doer;

	constructor(doer: Doer) {
		this.doer = doer;
	}

	private do<TReq, TRes>(
		method: string,
		params: TReq,
		opts?: CallOptions,
	): Promise<TRes> {
		console.debug(`ASession: ${method}`);
		return this.doer.do('s-authsession', serviceVersion, method, params, opts);
	}

	async attachUserWithAuthzToken(
		params: ServiceParams['attachUserWithAuthzToken'],
		opts?: CallOptions,
	): Promise<ServiceResponses['attachUserWithAuthzToken']> {
		return this.do('attach_user_with_authz_token', params, opts);
	}

	async createSession(
		params: ServiceParams['createSession'],
		opts?: CallOptions,
	): Promise<ServiceResponses['createSession']> {
		return this.do('create_session', params, opts);
	}

	async refreshSession(
		params: ServiceParams['refreshSession'],
		opts?: CallOptions,
	): Promise<ServiceResponses['refreshSession']> {
		return this.do('refresh_session', params, opts);
	}

	async endSession(
		params: ServiceParams['endSession'],
		opts?: CallOptions,
	): Promise<ServiceResponses['endSession']> {
		return this.do('end_session', params, opts);
	}
}
