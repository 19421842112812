import { CredTokenResponse, ISO8601DateTime } from '../types';

export const serviceVersion = '2022-11-24';

export type ServiceParams = {
	generateNonce: {
		sessionId: string;
		purpose: 'authentication' | 'verify_identifier' | 'attach_identifier';
	};
	exchangeToken: {
		sessionId: string;
		appleClientId: string;
		redirectUri: string | null;
		authzCode: string;
	};
};

export type ServiceResponses = {
	generateNonce: Nonce;
	exchangeToken: CredTokenResponse;
};

type Nonce = {
	nonce: string;
	expiresAt: ISO8601DateTime;
};
