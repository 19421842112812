import jsonClient, {
	RequestOptions as JsonClientRequestOptions,
} from '@wearemojo/json-client';

import { desnek, snek } from './casing';

export type RequestOptions = JsonClientRequestOptions & {
	disableRequestSnek?: boolean;
	disableResponseDesnek?: boolean;
};

const crpc = (baseUrl: string, baseOptions?: RequestOptions) => {
	const client = jsonClient(baseUrl, baseOptions);

	return async function crpcRequest<TReq, TRes>(
		path: string,
		body: TReq,
		options?: RequestOptions,
	): Promise<TRes> {
		const transformedBody = options?.disableRequestSnek ? body : snek(body);

		const res = await client('post', path, undefined, transformedBody, options);

		return options?.disableResponseDesnek ? (res as TRes) : desnek<TRes>(res);
	};
};

export default crpc;
export { desnek, snek };
