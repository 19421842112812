export const serviceVersion = '2023-09-29';

export type ServiceParams = {
	removeAccountIdentifiers: {
		key: string;
	};
	completeActivityDays: {
		userId: string;
		timezone: string;
		days: number;
	};
};

export type ServiceResponses = {
	removeAccountIdentifiers: void;
	completeActivityDays: void;
};
