export const serviceVersion = '2024-07-11';

export type ServiceParams = {
	getPseudonym: {
		userId: string;
	};
	recordConsent: {
		userId: string;
	};
};

export type ServiceResponses = {
	getPseudonym: Pseudonym;
	recordConsent: void;
};

type Pseudonym = {
	pseudonymId: string;
};
