import { ISO8601DateTime } from '../types';

export const serviceVersion = '2023-11-28';

export type ServiceParams = {
	getPollStats: {
		pollId: string;
	};
	recordPollResponse: {
		pollId: string;
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		optionKeys: string[];
	};
	listPollResponses: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		includePreviousResponses: boolean;
	};
};

export type ServiceResponses = {
	getPollStats: PollStats;
	recordPollResponse: void;
	listPollResponses: Response[];
};

type PollStats = {
	totalResponseCount: number;
	optionStats: {
		key: string;
		responseCount: number;
	}[];
};

type Response = {
	pollId: string;
	createdAt: ISO8601DateTime;
	optionKeys: string[];
};
