import { BlockContentType } from '@wearemojo/sanity-schema';

export const serviceVersion = '2024-10-07';

export type ServiceParams = {
	visualizeAnswers: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		modeId: string;
		answers: {
			questionKey: string;
			userInput: string;
		}[];
	};
	startSession: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		modeId: string;
	};
	processSessionResponse: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		sessionId: string;
		sessionQuestionId: string;
		userInput: string;
	};
	getSession: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		sessionId: string;
	};
};

export type ServiceResponses = {
	visualizeAnswers: Visualization;
	startSession: NewSession;
	processSessionResponse: SessionResult;
	getSession: Session;
};

type Visualization = {
	visualizationId: string;
	imageUrl: string;
	content: BlockContentType;
};

type NewSession = {
	sessionId: string;
	sessionQuestionId: string;
	content: BlockContentType;
};

type SessionResult = SessionResultQuestion | SessionResultSummary;

export type SessionResultQuestion = {
	type: 'question';
	params: {
		sessionQuestionId: string;
		content: BlockContentType;
	};
};

type SessionResultSummary = {
	type: 'summary';
	params: {
		content: BlockContentType;
	};
};

type Session = {
	id: string;
	modeId: string;
	questions: {
		id: string;
		content: BlockContentType;
		userInput: string | null;
	}[];
	summary: {
		content: BlockContentType;
	} | null;
};
