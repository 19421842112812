export interface QueryablePromise<T> extends Promise<T> {
	isFulfilled(): boolean;
	isPending(): boolean;
	isRejected(): boolean;
}

export const makeQueryablePromise = <T>(
	promise: Promise<T>,
): QueryablePromise<T> => {
	if (typeof (promise as QueryablePromise<T>)?.isFulfilled === 'function') {
		return promise as QueryablePromise<T>;
	}

	let isPending = true;
	let isRejected = false;
	let isFulfilled = false;

	const result = promise.then(
		(value: T) => {
			isFulfilled = true;
			isPending = false;
			return value;
		},
		(err: Error) => {
			isRejected = true;
			isPending = false;
			throw err;
		},
	) as QueryablePromise<T>;

	result.isFulfilled = () => isFulfilled;
	result.isPending = () => isPending;
	result.isRejected = () => isRejected;

	return result;
};
