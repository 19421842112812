export const serviceVersion = '2022-07-21';

export type ServiceParams = {
	startSelfDeletion: {
		userId: string;
	};
};

export type ServiceResponses = {
	startSelfDeletion: void;
};
