export const serviceVersion = '2022-11-24';

export type ServiceParams = {
	registerUser: {
		sessionId: string;
	};
};

export type ServiceResponses = {
	registerUser: Authz;
};

type Authz = {
	userId: string;
	authzToken: string;
};
