export const serviceVersion = '2022-11-10';

export type ServiceParams = {
	handleLogin: {
		userId: string;
		sso: string;
		sig: string;
	};
};

export type ServiceResponses = {
	handleLogin: URL;
};

type URL = {
	url: string;
};
