export const serviceVersion = '2022-08-26';

export type ServiceParams = {
	recordToken: {
		sessionId: string;
		platform: 'ios' | 'android';
		token: string;
	};
	// request snek is disabled here, due to misbehaving with `p256dh`
	recordWebSubscription: {
		session_id: string;
		platform: 'web';
		endpoint: string;
		keys: {
			p256dh: string;
			auth: string;
		};
	};
};

export type ServiceResponses = {
	recordToken: void;
	recordWebSubscription: void;
};
