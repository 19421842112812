import { SQScorePillar } from '@wearemojo/sanity-schema';

export const serviceVersion = '2024-06-17';

export type ServiceParams = {
	getCurrentVersion: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
	};
	submitAnswers: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		mode: 'cancelation' | 'manual';
		version: string;
		responses: Response[];
	};
	submitAnswersForOnboarding: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		onboardingType: 'app' | 'recapture';
		version: string;
		responses: Response[];
	};
	getResults: {
		userId: string;
	};
};

export type ServiceResponses = {
	getCurrentVersion: Version;
	submitAnswers: MeasurementResult;
	submitAnswersForOnboarding: MeasurementResult;
	getResults: MeasurementResult[];
};

type Version = {
	version: string;
};

type Response = {
	questionKey: string;
	answerKey: string;
};

type MeasurementResult = {
	recordedAt: string;
	mode: string;
	version: string;
	params: Record<string, string> | null;
	score: Score;
	pillarScores: PillarScore[] | null;
};

type Score = {
	value: number; // 0 - 100 integer
};

type PillarScore = {
	pillar: SQScorePillar;
	value: number; // 0-25 integer
};
