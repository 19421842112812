import { ISO8601DateTime } from '../types';

export const serviceVersion = '2021-10-21';

export type ServiceParams = {
	getSignupAttributionStatus: {
		userId: string;
	};
	attributeSignup: {
		userId: string;
		ipAddress: null; // field is required, but the app can't specify the IP
		headers: Record<string, string>;
		cookies: Record<string, string>;
		cookieConsent: CookieConsent | null;
		landingData: LandingData | null;
	};
};

export type ServiceResponses = {
	getSignupAttributionStatus: Status;
	attributeSignup: void;
};

type Status = {
	status: 'attributed' | 'awaiting_attribution' | 'not_attributable';
};

type CookieConsent = {
	version: '2023-08-30';
	timestamp: ISO8601DateTime;
	choices: {
		analytics: boolean;
		advertising: boolean;
	};
};

type LandingData = {
	version: '2023-01-31';
	totalClickCount: number | null;
	firstClick: Click | null;
	lastClicks: Click[];
};

type Click = {
	occurredAt: ISO8601DateTime;
	currentUrl: string | null;
	referrerUrl: string | null;
	parameters: Record<string, string>;
};
