export const serviceVersion = '2022-02-09';

export type ServiceParams = {
	getConfig: {
		platform: 'web' | 'ios' | 'android';
	};
	getUserConfig: {
		userId: string;
		platform: 'web' | 'ios' | 'android';
	};
};

export type ServiceResponses = {
	getConfig: Config;
	getUserConfig: UserConfig;
};

type Config = {
	appId: string;
	apiKey: string | null;
};

type UserConfig = {
	userId: string;
	userHash: string;
};
