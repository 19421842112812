import { ISO8601DateTime } from '../types';

export const serviceVersion = '2022-08-15';

export type ServiceParams = {
	recordEvents: {
		sessionId: string;
		events: {
			type: string;
			idempotencyKey: string;
			timestamp: ISO8601DateTime;
			meta: Record<string, unknown> | null;
		}[];
	};
};

export type ServiceResponses = {
	recordEvents: void;
};
