import { BackendConfig, BackendSystem } from '@wearemojo/api-client';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import { Platform } from 'react-native';

// on development builds, APP_VARIANT is undefined even though it
// should tell us which variant we are running, thus lets fallback
// to a default variant based on some heuristics
const getDefaultAppVariant = () => {
	if (Application.applicationId) {
		// com.wearemojo.mojo.devsim => devsim
		const variant = Application.applicationId
			.replace('com.wearemojo.mojo', '')
			.replace('.', '');

		if (variant) {
			return variant;
		}
	}

	if (Platform.OS === 'web' && __DEV__) {
		return 'dev';
	}

	return undefined;
};

export const APP_VARIANT =
	Constants?.expoConfig?.extra?.appVariant || getDefaultAppVariant();

export const isFinalAppRelease =
	!__DEV__ &&
	!APP_VARIANT &&
	(Application.applicationId === 'com.wearemojo.mojo' ||
		window.location?.host === 'app.mojo.so');

const hostnameTagRegex = /^(?<tag>[a-z\d-]+)\.wearemojo-app\.pages\.dev$/;

export const defaultBackendConfig = ((): BackendConfig => {
	const { system, env } = isFinalAppRelease
		? { system: BackendSystem.prod, env: 'prod' }
		: { system: BackendSystem.nonprod, env: 'aubergine' };

	const tag =
		window.location &&
		hostnameTagRegex.exec(window.location.hostname)?.groups?.tag;

	return {
		system,
		env,
		tag,
	};
})();

const isTaggedWebBuild =
	window?.location && hostnameTagRegex.test(window.location.hostname);

export const useBackendEnvs = () => {
	// we're writing this pretending that it'll be dynamic in the future
	//
	// TODO: load from system meta endpoints - https://api.mojo-[system].dev/_meta/config

	const systems: Record<
		BackendSystem,
		{
			envs: {
				emoji: string;
				name: string;
			}[];
			kill_switches: string[];
		}
	> = {
		// temporarily hardcoded from https://api.mojo-prod.dev/_meta/config
		[BackendSystem.prod]: {
			envs: [
				{ emoji: '🚀', name: 'prod' },
				// { emoji: '🧑‍🎤', name: 'staging' },
			],
			kill_switches: [],
		},
		// temporarily hardcoded from https://api.mojo-nonprod.dev/_meta/config
		[BackendSystem.nonprod]: {
			envs: [{ emoji: '🍆', name: 'aubergine' }],
			kill_switches: [],
		},
	};

	return (
		Object.entries(systems) as [
			BackendSystem,
			(typeof systems)[BackendSystem],
		][]
	).flatMap(([system, systemData]) =>
		systems[system].envs.map((e) => ({
			...e,
			system,
			kill_switches: systemData.kill_switches,
		})),
	);
};

export const defaultSanityConfig = {
	projectId: 'o3yougni',
	dataset: 'production',
	useCdn: true,
};

export const sandboxSanityConfig = {
	projectId: 'o3yougni',
	dataset: 'sandbox',
	useCdn: false,
};

const SENTRY_RN_CLIENT_DSN =
	'https://b9f68036bc654f45b222af5987822bc4@o1114715.ingest.sentry.io/6328314';
const SENTRY_RN_CLIENT_WEB_DSN =
	'https://3ed6bb1b8138fa06cad42b8b09d7e3c9@o1114715.ingest.sentry.io/4506002346213376';

export const defaultSentryConfig = {
	dsn: Platform.select({
		default: SENTRY_RN_CLIENT_DSN,
		web: SENTRY_RN_CLIENT_WEB_DSN,
	}),
	debug: false,
};

// TODO: Consider loading these from the clientsdk config
export const defaultGoogleClientId = {
	iosClientId:
		'771563933862-d1iu0au7oujfc92iqq0u28ivh2ladv5n.apps.googleusercontent.com',
	androidClientId:
		'771563933862-kanu4241aattvrod1ee2c0a3l7jqd9pf.apps.googleusercontent.com',
};

export const baseAppleRedirectPath = isFinalAppRelease
	? 'https://api.mojo-prod.dev/_meta/form_post_redirect'
	: 'https://api.mojo-nonprod.dev/_meta/form_post_redirect';

export const appleAuthClientId = isFinalAppRelease
	? 'com.wearemojo.mojo.web.prod'
	: 'com.wearemojo.mojo.web.nonprod';

export const googleWebClientId = isFinalAppRelease
	? '523117596961-l1610bvngrht76h1s5ca3g3vt00v08ui.apps.googleusercontent.com'
	: '771563933862-u4jfnd1eupd0c9h3qc6m26rel86ec0e4.apps.googleusercontent.com';

export const stripePublicKey = isFinalAppRelease
	? 'pk_live_RRxjv8wxz36DzDK8Yu993nLT00jJljYulQ'
	: 'pk_test_PqhdwKR6qK0OncE3FMwYuIlR00ryotR5hA';

export const itunesItemId = '1628626105';

export const appsFlyerDevKey = 'JGdvZdkAqnsabiaUjDc8Ff';
export const appsFlyerIOSAppId = isFinalAppRelease
	? itunesItemId // production app id
	: '6471829626'; // devsim app id

export const logRocketSlug = isFinalAppRelease
	? 'zjxe3o/mojo-prod'
	: 'zjxe3o/mojo-nonprod';

export const logRocketHostName = isTaggedWebBuild
	? undefined
	: isFinalAppRelease
		? 'mojo.so'
		: 'mojo-nonprod.com';
