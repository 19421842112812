export const serviceVersion = '2022-12-05';

export type ServiceParams = {
	createSubscription: {
		userId: string;
	};
};

export type ServiceResponses = {
	createSubscription: void;
};
