export const serviceVersion = '2022-06-07';

export type ServiceParams = {
	getPreferences: {
		userId: string;
	};
};

export type ServiceResponses = {
	getPreferences: UserPreference;
};

type UserPreference = {};
