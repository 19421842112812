import { IdentifierType, ISO8601DateTime } from '../types';

export const serviceVersion = '2022-11-24';

export type ServiceParams = {
	listUserIdentifiers: {
		userId: string;
	};
	removeIdentifier: {
		userId: string;
		identifierId: string;
	};
	attachEmailUnverified: {
		userId: string;
		email: string;
	};
	setMainEmail: {
		userId: string;
		identifierId: string;
	};
	attachIdentifierWithCredToken: {
		sessionId: string;
		type: IdentifierType;
		value: string;
		credToken: string;
	};
	attachIdentifierWithVerificationToken: {
		sessionId: string;
		type: IdentifierType;
		value: string;
		verificationToken: string;
	};
	verifyIdentifierWithVerificationToken: {
		sessionId: string;
		identifierId: string;
		verificationToken: string;
	};
};

export type ServiceResponses = {
	listUserIdentifiers: Identifier[];
	removeIdentifier: void;
	attachEmailUnverified: IdentifierId;
	setMainEmail: void;
	attachIdentifierWithCredToken: IdentifierId;
	attachIdentifierWithVerificationToken: IdentifierId;
	verifyIdentifierWithVerificationToken: void;
};

type IdentifierId = {
	identifierId: string;
};

type Identifier = {
	id: string;
	type: IdentifierType;
	value: string;
	firstVerifiedAt: ISO8601DateTime | null;
};
